import React from "react";
import { Icon } from "../icon";
import type { IconProps } from "../icon";
import "./icon-text.scss";

type Alignment = "left" | "right" | "center";

const preserveAspectRatioValues = {
  left: "xMaxYMid meet",
  center: "xMidYMid meet",
  right: "xMinYMid meet",
};

export type IconTextProps = Omit<IconProps, "id"> & {
  className?: string;
  iconId: IconProps["id"];
  alignment?: Alignment;
};

export const IconText: React.FC<IconTextProps> = ({
  children,
  iconId,
  alignment = "left",
  className,
  ...rest
}) => (
  <span
    className={`icon-text icon-text--${alignment}${
      className ? ` ${className}` : ""
    }`}
  >
    <Icon
      id={iconId}
      className={`icon--${alignment}`}
      {...rest}
      preserveAspectRatio={preserveAspectRatioValues[alignment]}
    />
    {children}
  </span>
);
