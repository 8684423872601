import { Link } from "gatsby-plugin-react-i18next/dist/Link";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import BurgerToggle from "./burger";
import LanguageSelector from "../language-selector";
import "./navigation.scss";
import Portal from "@reach/portal";
import { Icon } from "../icon";

type NavItem = {
  link: string;
  i18nKey?: string;
  sub?: ReadonlyArray<NavItem>;
};

export type NavigationProps = {
  pages: ReadonlyArray<NavItem>;
  className?: string;
};

const NavItems: React.FC<NavigationProps> = ({ pages, className }) => {
  const { t } = useTranslation("navigation");
  return (
    <ul className={`pages${className ? ` ${className}` : ""}`}>
      {pages.map(n => (
        <li key={n.link}>
          <Link to={`/${n.link}/`}>
            <span>{t(n.i18nKey ? n.i18nKey : n.link)}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Navigation: React.FC<NavigationProps> = ({ pages, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log("navData", navData);
  const toggle = useCallback(() => {
    console.log("isOpen", isOpen);
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <>
      <NavItems
        pages={pages}
        className={`hide-on-small${className ? ` ${className}` : ""}`}
      />
      <LanguageSelector className="hero hide-on-small" />
      <div className="hide-on-large">
        <div className="navbar-right">
          <a
            href="tel:+66888914937"
            title="Rufen Sie uns an"
            className="burger-toggle svg-button"
          >
            <Icon id="phone" aria-label="Icon of phone" />
          </a>
          <Portal>
            <aside
              id="navbarResponsive"
              className={`navigation-sidenav full-centered ${
                isOpen ? "open" : ""
              }`}
            >
              <NavItems pages={pages} className={className} />
              <LanguageSelector displayMode="expanded" />
            </aside>
          </Portal>
          <BurgerToggle
            onClick={toggle}
            isOpen={isOpen}
            aria-controls="navbarResponsive"
          />
        </div>
      </div>
    </>
  );
};

export default Navigation;
