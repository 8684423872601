import React from "react";
import { Helmet } from "gatsby-plugin-react-i18next";
import { SVGIconSprite } from "../components/icon";
import Header from "../components/header";
import Footer from "../components/footer";
import Address from "../components/address";

const MainLayout: React.FC = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <SVGIconSprite />
      <Header />
      <div id="content-wrapper">
        {children}
        <Footer className="full-centered">
          <Address />
        </Footer>
      </div>
    </>
  );
};

export default MainLayout;
