import React, { ButtonHTMLAttributes } from "react";
import "./burger.scss";

type BurgerToggleProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOpen: boolean;
};

const BurgerToggle: React.FC<BurgerToggleProps> = ({
  isOpen,
  onClick,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`hamburger hamburger--squeeze${isOpen ? " is-active" : ""}`}
      type="button"
      onClick={onClick}
      aria-expanded={isOpen ? "true" : "false"}
      aria-label="Toggle navigation"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};

export default BurgerToggle;
