module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/tsch/Documents/htdocs/seth-thee-gatsby/locales","languages":["en","th","de"],"defaultLanguage":"th","siteUrl":"https://beta.seth-thee.com","i18nextOptions":{"interpolation":{"escapeValue":false},"ns":["common"],"defaultNS":"common","keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"เศรษฐีเรสซีเด้นท์","short_name":"เศรษฐี","description":"โรงแรมเศรษฐีเรสซิเด้นท์ สไตล์บูติแห่งใหม่ในตัวอำเภอพล","lang":"th","start_url":"/","background_color":"#c00054","theme_color":"#fee71c","icon":"src/assets/icons/favicon.svg","display":"standalone","icon_options":{"purpose":"any maskable"},"icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"localize":[{"start_url":"/de/","lang":"de","name":"Seth Thee Residence","short_name":"Seth Thee","description":"Seth Thee Residence ist ein Hotel in Phon, Khon Kaen, Thailand."},{"start_url":"/en/","lang":"en","name":"Seth Thee Residence","short_name":"Seth Thee","description":"Seth Thee Residence is a hotel in Phon, Khon Kaen, Thailand."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fa4c6e7617d3901fcaf67a0a8119a1e8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Fahkwang","Kanit:n3,n6"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/fonts/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
