import React from "react";

import { Link, useI18next } from "gatsby-plugin-react-i18next";
import "./language-selector.scss";
import { Hash } from "crypto";

type LanguageSelectorProps = {
  className?: string;
  displayMode?: "inline" | "expanded";
};
const languageNames: { [key: string]: string } = {
  de: "Deutsch",
  en: "English",
  th: "ไทย",
};
const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  className,
  displayMode = "inline",
}) => {
  const {
    t,
    languages,
    originalPath,
    i18n: { language },
  } = useI18next();
  return (
    <div
      className={`language-selector${
        displayMode === "expanded" ? ` expanded` : ""
      }${className ? ` ${className}` : ""}`}
    >
      {displayMode === "expanded" && <h2>{t("language")}</h2>}
      <ul>
        {languages
          .filter(l => l !== language)
          .map(lng => (
            <li key={lng}>
              <Link to={originalPath} language={lng}>
                {displayMode === "expanded" ? languageNames[lng] : lng}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
