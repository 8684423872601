import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { IconText } from "../icon-text";
import "./address.scss";

const Address: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <address>
      <div itemProp="name">
        {language === "th"
          ? "โรงแรมเศรษฐีเรสซีเด้นท์"
          : "Hotel Seth Thee Residence"}
      </div>
      <a
        href="tel:+66888914937"
        rel="noopener"
        title={t("telephone_link_title")}
      >
        <IconText iconId="phone">
          <span itemProp="telephone">+66 (0)88 8914937</span>
        </IconText>
      </a>
      <Link to="email" title={t("email_link_title")}>
        <IconText iconId="envelope">
          <span itemProp="email">booking@seth-thee.com</span>
        </IconText>
      </Link>
      <a
        itemProp="hasMap"
        href="https://goo.gl/maps/TErfO"
        rel="noopener"
        title={t("map_link_title")}
        target="_blank"
      >
        <IconText iconId="map-marker-new">
          <span
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress"
          >
            {language === "th" ? (
              <>
                <span itemProp="streetAddress">133/3 ถ. เจริญสุข</span>
                <br />
                <span itemProp="addressLocality">ต.เมืองพล, อ.พล</span>
                <br />
                <span itemProp="addressRegion">ขอนแก่น</span>&nbsp;
                <span itemProp="postalCode">40120</span>
                <br />
                <span itemProp="addressCountry">เมืองไทย</span>
              </>
            ) : (
              <>
                <span itemProp="streetAddress">133/3 Charoen Suk Road</span>
                <br />
                <span itemProp="addressLocality">Mueang Phon, Amphoe Phon</span>
                <br />
                <span itemProp="addressRegion">Khon Kaen</span>&nbsp;
                <span itemProp="postalCode">40120</span>
                <br />
                <span itemProp="addressCountry">Thailand</span>
              </>
            )}
          </span>
        </IconText>
      </a>
    </address>
  );
};

export default Address;
