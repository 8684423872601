import { graphql, useStaticQuery } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next/dist";

import React, { useMemo } from "react";
import Headroom from "react-headroom";
import { Icon } from "../icon";
import Navigation, { NavigationProps } from "../navigation";
import "./header.scss";

export const navDataQuery = graphql`
  query NavigationQuery {
    site {
      siteMetadata {
        navigation {
          pages {
            link
          }
        }
      }
    }
  }
`;
type NavigationData = {
  site: {
    siteMetadata: {
      navigation: NavigationProps;
    };
  };
};

const Header: React.FC = () => {
  const { t } = useTranslation("navigation");
  const data: NavigationData = useStaticQuery(navDataQuery);
  const navData = useMemo(() => data.site.siteMetadata.navigation, []);
  return (
    <Headroom>
      <header>
        <nav className="full-centered">
          <Link
            className="home-link svg-button left-align"
            to="/"
            title={t("home")}
          >
            <Icon id="logo" aria-label="logo" />
          </Link>
          <Navigation {...navData} className="inline-list" />
        </nav>
      </header>
    </Headroom>
  );
};

export default Header;
