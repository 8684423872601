import React from "react";
import type { SVGAttributes } from "react";
import Sprite from "../../assets/sprite.svg";

export type IconId =
  | "address-card"
  | "bars"
  | "close"
  | "envelope"
  | "envelope-open"
  | "envelope-square"
  | "facebook"
  | "facebook-f"
  | "facebook-messenger"
  | "facebook-square"
  | "foursquare"
  | "google-plus"
  | "google-plus-g"
  | "google-plus-square"
  | "logo"
  | "map"
  | "map-marker"
  | "map-marker-new"
  | "map-marker-alt"
  | "map-pin"
  | "map-signs"
  | "mobile"
  | "mobile-alt"
  | "phone"
  | "phone-square"
  | "phone-volume"
  | "thumbs"
  | "times"
  | "times-circle";

export type IconProps = SVGAttributes<SVGElement> & {
  id: IconId;
  className?: string;
};

export const Icon: React.FC<IconProps> = ({
  id,
  fill,
  role,
  focusable,
  className,
  ...rest
}) => (
  <svg
    {...rest}
    fill={fill ? fill : "currentColor"}
    role={role || "img"}
    focusable={focusable || "false"}
    className={`icon icon-${id}${className ? ` ${className}` : ""}`}
  >
    <use xlinkHref={`#${id}`} />
  </svg>
);

export const SVGIconSprite = Sprite;
